import { combineReducers } from 'redux'
import authSlice from "./auth/authSlice";
import { authApi } from "./auth/authApi";
import { employeeApi } from "./employee/employeeApi";
import employeeSlice from "./employee/employeeSlice";
import eventSlice from "./event/eventSlice";
import { eventApi } from "./event/eventApi";
import { projectApi } from "./project/projectApi";
import projectSlice from "./project/projectSlice";
import { roleApi } from "./role/roleApi";
import roleSlice from "./role/roleSlice";
import weekendSlice from "./weekend/weekendSlice";
import { weekendApi } from "./weekend/weekendApi";
import { trackerApi } from "./tracker/trackerApi";
import trackerSlice from "./tracker/trackerSlice";
import { taskApi } from "./task/taskApi";
import taskSlice from "./task/taskSlice";
import { companyApi } from "./company/companyApi";
import companySlice from "./company/companySlice";
import { industryApi } from "./industry/industryApi";
import industrySlice from "./industry/industrySlice";

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [employeeApi.reducerPath]: employeeApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [weekendApi.reducerPath]: weekendApi.reducer,
  [trackerApi.reducerPath]: trackerApi.reducer,
  [taskApi.reducerPath]: taskApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [industryApi.reducerPath]: industryApi.reducer,
  auth: authSlice,
  employee: employeeSlice,
  event: eventSlice,
  project: projectSlice,
  role: roleSlice,
  weekend: weekendSlice,
  tracker: trackerSlice,
  task: taskSlice,
  company: companySlice,
  industry: industrySlice,
})

export default rootReducer
