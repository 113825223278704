import React from 'react'
import './style.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useAppSelector } from "../../../store/hooks";
import { useEntriesByEmployeeQuery } from "../../../store/tracker/trackerApi";
import { useMeQuery } from "../../../store/auth/authApi";
import TrackerResultItem from "./components/TrackerResultItem";
import TrackerTimer from "./components/TrackerTimer";
import StatisticTimer from "./components/StatisticTimer";

export const Tracker = () => {

  const { entries } = useAppSelector(state => state.tracker);
  const { user } = useAppSelector(state => state.auth);
  const { refetch } = useEntriesByEmployeeQuery(String(user?.id))
  useMeQuery()

  return (
    <div>
      <h2>Tracker</h2>
      <TrackerTimer employeeId={Number(user?.id)} onChange={refetch}/>
      <Tabs>
        <TabList>
          <Tab>Tracker</Tab>
          <Tab>Stat</Tab>
        </TabList>
        <TabPanel>
          <div className='tracker-result'>
            {entries && entries.map((item, index) => (
              <TrackerResultItem item={item} key={index}/>
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          {entries &&
            <StatisticTimer entries={entries}/>
          }
        </TabPanel>
      </Tabs>

    </div>
  )
}