import React from "react";
import { IEntry } from "../../../../types/tracker";
import { differenceInSeconds, endOfWeek, intervalToDuration, parseISO, startOfWeek } from "date-fns";
import { zeroPad } from "../../../../utils/helpers";

const StatisticTimer = ({ entries }:{entries: IEntry[]}) => {

  const weekStat = () => {
    let weekSeconds = 0;
    for(const entry of entries){
      if(parseISO(entry.dateStarted).getTime() >= startOfWeek(new Date()).getTime() && parseISO(entry.dateStarted).getTime() <= endOfWeek(new Date()).getTime()) {
        if (entry.dateFinished) {
          weekSeconds = weekSeconds + differenceInSeconds(parseISO(entry.dateFinished), parseISO(entry.dateStarted))
        }
      }
    }
    const duration = intervalToDuration({
      start: 0,
      end: weekSeconds * 1000
    })
    return `${zeroPad(duration.hours)}:${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`
  }

  return (
    <div className='statistic'>
      <div className='row'>
        <p>By current week</p>
        <p>{weekStat()}</p>
      </div>
    </div>
  )
}

export default StatisticTimer