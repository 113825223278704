import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import { IIndustry } from "../../types/industry";

export const industryApi = createApi({
  reducerPath: 'industryApi',
  baseQuery: customFetchBase,
  tagTypes: ['Industry'],
  endpoints: (builder) => ({
    industries: builder.query<{data: IIndustry[]}, void>({
      query: () => ({
        url: 'industries',
        method: 'GET'
      }),
      providesTags: ['Industry']
    }),
    singleIndustry: builder.query<{data: IIndustry}, string>({
      query: (id) => ({
        url: `industries/${id}`,
        method: 'GET'
      }),
    }),
    addIndustry: builder.mutation<void, Pick<IIndustry, 'name'>>({
      query: (data) => ({
        url: `industries`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Industry']
    }),
    changeIndustry: builder.mutation<void, IIndustry>({
      query: (data) => ({
        url: `industries/${data.id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Industry']
    }),
    deleteIndustry: builder.mutation<void, string>({
      query: (id) => ({
        url: `industries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Industry']
    }),
  }),
})

export const {
  useIndustriesQuery,
  useAddIndustryMutation,
  useChangeIndustryMutation,
  useDeleteIndustryMutation,
  useSingleIndustryQuery
} = industryApi
