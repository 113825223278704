import React, { useEffect } from 'react'
import './style.scss'
import { useForm } from "react-hook-form";
import Input from "../../../components/Input";
import { useNavigate } from "react-router-dom";
import { useAddCompanyMutation } from "../../../../store/company/companyApi";
import { ICompany } from "../../../../types/company";
import { ROUTES } from "../../../../routes/routes";

export const NewCompany = () => {

  const [addCompany, { isSuccess }] = useAddCompanyMutation();
  const { register, handleSubmit, formState: { errors } } = useForm<Omit<ICompany, 'id'>>();
  const navigate = useNavigate();

  const onSubmit = (data: Omit<ICompany, 'id'>) => {
    addCompany(data)
  }

  useEffect(()=>{
    if(isSuccess){
      navigate(ROUTES.companies)
    }
  }, [isSuccess])

  return (
    <div className='new-company-wrapper'>
      <h3>Add company</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type={'text'} label={'Company name'} register={register} name={'name'} errors={errors} options={{ required: true }}/>
        <button className='btn btn-primary' type={'submit'}>Create</button>
      </form>
    </div>
  )
}