import React from 'react'
import { FieldErrors, RegisterOptions, UseFormRegister } from "react-hook-form";

type CheckboxProps = {
  placeholder?: string,
  label?: string,
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  name: string;
  options?: RegisterOptions;
  errors: FieldErrors;
}

const Checkbox = (props: CheckboxProps) => {
  const { placeholder, label, register, name, options, errors } = props;
  return (
    <div className='form-group'>
      {label &&
        <label>{label}</label>
      }
      <div>
        <input
          type={'checkbox'}
          placeholder={placeholder}
          id={name}
          {...register(name, options)}
        />
        <label htmlFor={name}/>
      </div>
      {errors[name] && <div className='error-text'>This field is required</div>}
    </div>
  )
}

export default Checkbox