import { createSlice } from '@reduxjs/toolkit';
import { taskApi } from "./taskApi";
import { TaskState } from "../../types/task";

const initialState: TaskState = {
  tasks: null,
};

export const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      taskApi.endpoints.tasksByProject.matchFulfilled,
      (state, { payload }) => {
        state.tasks = payload.data
      }
    )
  },
});

export default slice.reducer;
