import React, { ChangeEvent } from 'react'
import { FieldErrors, RegisterOptions, UseFormRegister } from "react-hook-form";
import { ISelect } from "../../../types/project";

type SelectProps = {
  label?: string,
  isMultiple?: boolean
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  name: string;
  options?: RegisterOptions;
  errors: FieldErrors;
  values: ISelect[]
  onChange?: (s: string) => void
}

const Select = (props: SelectProps) => {

  const { label, values, register, name, options, errors, isMultiple, onChange } = props;

  const selectHandler = (e:ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(e.target.options[e.target.selectedIndex].text)
  }

  return (
    <div className='form-group'>
      {label &&
        <label>{label}</label>
      }
      <select {...register(name, options)} multiple={isMultiple} onChange={selectHandler}>
        {values.map(item => (
          <option value={item.value} key={item.value}>{item.label}</option>
        ))}
      </select>
      {errors[name] && <div className='error-text'>This field is required</div>}
    </div>
  )
}

export default Select