import { createSlice } from '@reduxjs/toolkit';
import { industryApi } from "./industryApi";
import { IndustryState } from "../../types/industry";

const initialState: IndustryState = {
  industries: null,
  single: null
};

export const slice = createSlice({
  name: 'industry',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      industryApi.endpoints.industries.matchFulfilled,
      (state, { payload }) => {
        state.industries = payload.data
      }
    )
    builder.addMatcher(
      industryApi.endpoints.singleIndustry.matchFulfilled,
      (state, { payload }) => {
        state.single = payload.data
      }
    )
  },
});

export default slice.reducer;
