import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import { IRole } from "../../types/role";

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: customFetchBase,
  tagTypes: ['Role'],
  endpoints: (builder) => ({
    roles: builder.query<{data: IRole[]}, void>({
      query: () => ({
        url: 'roles',
        method: 'GET'
      }),
    }),
  }),
})

export const {
  useRolesQuery
} = roleApi
