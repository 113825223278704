import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import { IEntry, IEntryCreate } from "../../types/tracker";

export const trackerApi = createApi({
  reducerPath: 'trackerApi',
  baseQuery: customFetchBase,
  tagTypes: ['Tracker'],
  endpoints: (builder) => ({
    entriesByEmployee: builder.query<{data: IEntry[]}, string>({
      query: (id) => ({
        url: `tracker/by-employee/${id}`,
        method: 'GET'
      }),
    }),
    addEntry: builder.mutation<{data:IEntry}, IEntryCreate>({
      query: (data) => ({
        url: `tracker`,
        method: 'POST',
        body: data
      }),
    }),
    changeEntry: builder.mutation<{data:IEntry}, {id: string, data: IEntryCreate}>({
      query: (data) => ({
        url: `tracker/${data.id}`,
        method: 'PATCH',
        body: data.data
      }),
    }),
  }),
})

export const {
  useAddEntryMutation,
  useEntriesByEmployeeQuery,
  useChangeEntryMutation
} = trackerApi
