import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './style.scss'
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ROLES } from "../../../types/role";
import { logout } from "../../../store/auth/authSlice";
import { ROUTES } from "../../../routes/routes";

export const Sidebar = () => {

  const navigate = useNavigate();
  const { role, isAuthenticated } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  const logoutHandler = () => {
    dispatch(logout())
  }

  useEffect(()=>{
    if(!isAuthenticated){
      navigate(ROUTES.login)
    }
  }, [isAuthenticated])

  return (
    <aside className="sidebar">
      <ul>
        {role == ROLES.MANAGER ?
          <>
            <li>
              <Link to={ROUTES.home}>
                Deck
              </Link>
            </li>
            <li>
              <Link to={ROUTES.tracker}>
                Tracker
              </Link>
            </li>
            <li>
              <Link to={ROUTES.employees}>
                Employees
              </Link>
            </li>
            <li>
              <Link to={ROUTES.projects}>
                Projects
              </Link>
            </li>
            <li>
              <Link to={ROUTES.companies}>
                Companies
              </Link>
            </li>
            <li>
              <Link to={ROUTES.industries}>
                Industries
              </Link>
            </li>
          </>
          :
          <>
            <li>
              <Link to={ROUTES.tracker}>
                Tracker
              </Link>
            </li>
          </>
        }
        <li>
          <Link to={ROUTES.weekends}>
            Company weekends
          </Link>
        </li>
        <li>
          <Link to={ROUTES.me}>
            My profile
          </Link>
        </li>
      </ul>
      <button onClick={logoutHandler}>Logout</button>
    </aside>
  )
}