import { createSlice } from '@reduxjs/toolkit';
import { roleApi } from "./roleApi";
import { RolesState } from "../../types/role";

const initialState: RolesState = {
  roles: null
};

export const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      roleApi.endpoints.roles.matchFulfilled,
      (state, { payload }) => {
        state.roles = payload.data
      }
    )
  },
});

export default slice.reducer;
