import { createSlice } from '@reduxjs/toolkit';
import { eventApi } from "./eventApi";
import { EventState } from "../../types/event";

const initialState: EventState = {
  events: null,
  single: null
};

export const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      eventApi.endpoints.events.matchFulfilled,
      (state, { payload }) => {
        state.events = payload.data
      }
    )
    builder.addMatcher(
      eventApi.endpoints.singleEvent.matchFulfilled,
      (state, { payload }) => {
        state.single = payload.data
      }
    )
  },
});

export default slice.reducer;
