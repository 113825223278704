import React from 'react'
import '../Single/style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { useMeQuery } from "../../../../store/auth/authApi";

export const Me = () => {
  const { user } = useAppSelector(state => state.auth);
  useMeQuery()

  return (
    <>
      {user &&
      <div className='single-employee'>
        {user.image ?
          <img src={user.image} alt=""/>
          :
          <div className='no-image'>N/A</div>
        }
        <h3>{user?.name}</h3>
        <div className='vacation'>
          <p>Day off: {user.dayOff}</p>
          <p>Sick leave: {user.sickLeave}</p>
        </div>
      </div>
      }
    </>
  )
}