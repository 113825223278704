import React from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { Link } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import { ROUTES } from "../../../../routes/routes";
import { useIndustriesQuery } from "../../../../store/industry/industryApi";

export const Industries = () => {

  const { industries } = useAppSelector(state => state.industry);
  const { isLoading } = useIndustriesQuery()

  return (
    <>
      <Loader isLoading={isLoading}/>
      {industries && industries.length > 0 &&
        <div className='industries-wrapper'>
          <h3>Industries list</h3>
          <div className='industries-header'>
            <h5>Name</h5>
            <h5>Total projects</h5>
          </div>
          {industries.map(industry => (
            <Link to={`/industry/${industry.id}`} key={industry.id} className='industry-item'>
              <p>{industry.name}</p>
              <p>{industry.total}</p>
            </Link>
          ))}
        </div>
      }
      <Link to={ROUTES.newIndustry} className='add-new'>+</Link>
    </>
  )
}