import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist'
import rootReducer from "./reducer";
import { authApi } from "./auth/authApi";
import storage from 'redux-persist/lib/storage'
import { employeeApi } from "./employee/employeeApi";
import { eventApi } from "./event/eventApi";
import { projectApi } from "./project/projectApi";
import { roleApi } from "./role/roleApi";
import { weekendApi } from "./weekend/weekendApi";
import { trackerApi } from "./tracker/trackerApi";
import { taskApi } from "./task/taskApi";
import { companyApi } from "./company/companyApi";
import { industryApi } from "./industry/industryApi";

const persistConfig = {
  key: 'jelly',
  storage,
  whitelist: ['auth', 'tracker']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false })
      .concat(
        authApi.middleware,
        employeeApi.middleware,
        eventApi.middleware,
        projectApi.middleware,
        roleApi.middleware,
        weekendApi.middleware,
        trackerApi.middleware,
        taskApi.middleware,
        companyApi.middleware,
        industryApi.middleware,
      );
  },
})

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

