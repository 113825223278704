import React from 'react'
import { FieldErrors, RegisterOptions, UseFormRegister } from "react-hook-form";

type InputProps = {
  type: 'text' | 'email' | 'tel' | 'password' | 'number' | 'date',
  placeholder?: string,
  label?: string,
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  name: string;
  options?: RegisterOptions;
  errors: FieldErrors;
  disabled?: boolean;
}

const Input = (props: InputProps) => {
  const { type, placeholder, label, register, name, options, errors, disabled } = props;
  return (
    <div className='form-group'>
      {label &&
        <label>{label}</label>
      }
      <input
        type={type}
        placeholder={placeholder}
        id={name}
        disabled={disabled}
        {...register(name, options)}
      />
      {errors[name] && <div className='error-text'>This field is required</div>}
    </div>
  )
}

export default Input