import { useAppSelector } from "../store/hooks";
import { isLoggedIn } from "../store/auth/authSelector";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { ROUTES } from "./routes";

const AuthGuard = () => {
  const isUserLoggedIn = useAppSelector(isLoggedIn);

  if(!isUserLoggedIn){
    return <Navigate to={ROUTES.login}/>
  }

  return (
    <>
      <Outlet/>
    </>
  )
}

export default AuthGuard