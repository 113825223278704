import React, { useEffect } from 'react'
import './style.scss'
import { useForm } from "react-hook-form";
import Input from "../../../components/Input";
import { useNavigate } from "react-router-dom";
import { useAddIndustryMutation } from "../../../../store/industry/industryApi";
import { IIndustry } from "../../../../types/industry";
import { ROUTES } from "../../../../routes/routes";

export const NewIndustry = () => {

  const [addIndustry, { isSuccess }] = useAddIndustryMutation();
  const { register, handleSubmit, formState: { errors } } = useForm<Omit<IIndustry, 'id'>>();
  const navigate = useNavigate();

  const onSubmit = (data: Omit<IIndustry, 'id'>) => {
    addIndustry(data)
  }

  useEffect(()=>{
    if(isSuccess){
      navigate(ROUTES.industries)
    }
  }, [isSuccess])

  return (
    <div className='new-industry-wrapper'>
      <h3>Add industry</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type={'text'} label={'Industry name'} register={register} name={'name'} errors={errors} options={{ required: true }}/>
        <button className='btn btn-primary' type={'submit'}>Create</button>
      </form>
    </div>
  )
}