import React, { ReactNode } from 'react'
import './style.scss'
import classNames from "classnames";

type ModalProps = {
  isOpen: boolean,
  setClose: () => void,
  children: ReactNode,
  id: string
}

const Modal = ({ isOpen, setClose, children, id }: ModalProps) => {

  const classes = classNames('popup-wrapper', isOpen && 'active')

  const closeHandler = () => {
    (document.getElementById(id) as HTMLDivElement).classList.remove('active')
    setClose()
  }

  return (
    <div className={classes} id={id} onClick={closeHandler}>
      <div className='popup' onClick={e => e.stopPropagation()}>
        <div className='popup-content'>
          <button type={'button'} onClick={closeHandler} className='close-popup' >
            X
          </button>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal