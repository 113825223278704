import React, { RefObject, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import { useProjectsForEventQuery } from "../../../../../store/project/projectApi";
import Modal from "../../../../components/Modal";
import FullCalendar from "@fullcalendar/react";
import { IEventAdd, IEventModal } from "../../../../../types/event";
import { useForm } from "react-hook-form";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Checkbox from "../../../../components/Checkbox";
import { useMeQuery } from "../../../../../store/auth/authApi";
import { useAddEventMutation } from "../../../../../store/event/eventApi";
import { hours } from "../../constants";

type ModalProps = {
  calendar: RefObject<FullCalendar>,
  isOpen: boolean
  setClose: () => void
  employee: string
  initialValues: {dateStart: string, dateEnd: string}
}

export const AddModal = ({ calendar, isOpen, setClose, employee, initialValues }: ModalProps) => {

  const { projectsEvent } = useAppSelector(state => state.project);
  const { user } = useAppSelector(state => state.auth);
  const { register, handleSubmit, formState: { errors }, reset, watch } = useForm<IEventModal>();
  const [projectTitle, setProjectTitle] = useState('');
  const [addEvent, { isLoading }] = useAddEventMutation()
  const projects = projectsEvent ? projectsEvent.map(project => ({
    value: project.id,
    label: project.name
  })) : [];
  useMeQuery()
  useProjectsForEventQuery();

  const projectHandler = (title: string) => {
    setProjectTitle(title)
  }

  useEffect(()=>{
    reset(initialValues)
  }, [isOpen]);

  const onSubmit = (data: IEventModal) => {
    const calendarApi = calendar.current?.getApi();
    const title = data.sickLeave ? 'Sick Leave' : data.dayOff ? 'Day Off' : projectTitle;
    addEvent({
      title: title,
      project: data.project,
      dateStart: data.dateStart + 'T00:00:00',
      dateEnd: data.dateEnd + 'T00:00:00',
      employee: employee,
      creator: Number(user?.id),
      dayOff: data.dayOff,
      sickLeave: data.sickLeave,
      hours: !(data.dayOff || data.sickLeave) ? data.hours : ''
    }).unwrap().then((response: IEventAdd) => {
      calendarApi?.addEvent({
        id: response.id,
        title: title,
        start: data.dateStart + 'T00:00:00',
        end: data.dateEnd + 'T00:00:00',
        resourceId: employee,
        allDay: true,
        color: response.color,
        creator: response.email,
        extendedProps: {
          'hours': !(data.dayOff || data.sickLeave) ? data.hours : '',
          'creator': response.email,
        }
      })
      setClose && setClose()
    })
  }

  return (
    <Modal isOpen={isOpen} setClose={setClose} id={'add-event'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!(watch().dayOff || watch().sickLeave) &&
          <Select register={register} name={'project'} errors={errors} values={projects} label={'Project'} onChange={projectHandler}/>
        }
        <Input type={'date'} register={register} name={'dateStart'} errors={errors} label={'Date start'}/>
        <Input type={'date'} register={register} name={'dateEnd'} errors={errors} label={'Date start'}/>
        {!(watch().dayOff || watch().sickLeave) &&
          <Select register={register} name={'hours'} errors={errors} values={hours} label={'Hours'}/>
        }
        {!watch().sickLeave &&
          <Checkbox register={register} name={'dayOff'} errors={errors} label={'Day off?'}/>
        }
        {!watch().dayOff &&
          <Checkbox register={register} name={'sickLeave'} errors={errors} label={'Sick leave?'}/>
        }
        <button className="btn btn-primary" disabled={isLoading}>Save changes</button>
      </form>
    </Modal>
  )
}