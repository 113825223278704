import React, { useEffect, useState } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteEmployeeMutation,
  useInviteEmployeeMutation,
  useLazySingleEmployeeQuery,
} from "../../../../store/employee/employeeApi";
import { config } from "../../../../utils/config";
import Modal from "../../../components/Modal";
// eslint-disable-next-line
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ROUTES } from "../../../../routes/routes";
import { Loader } from "../../../components/Loader";

export const Employee = () => {
  const { single } = useAppSelector(state => state.employee);
  const [showPopup, setShowPopup] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [getEmployee, { isLoading }] = useLazySingleEmployeeQuery();
  const [invite] = useInviteEmployeeMutation();
  const [deleteEmployee] = useDeleteEmployeeMutation();
  const { id } = useParams();
  const navigate = useNavigate()

  useEffect(()=>{
    invite(String(id)).then(()=>{
      getEmployee(String(id))
    })
  }, [id]);

  const inviteHandler = () => {
    setShowPopup(true)
  }

  const deleteHandler = () => {
    deleteEmployee(String(id)).then(()=>{
      navigate(ROUTES.employees)
    })
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {single && single.id === Number(id) && !isLoading &&
        <div className='single-employee'>
          {single.image ?
            <img src={single.image} alt=""/>
            :
            <div className='no-image'>N/A</div>
          }
          <h3>{single.name}</h3>
          <p>{single.email}</p>
          <div className='buttons'>
            {!single.isActivated &&
              <button onClick={inviteHandler} type={'button'} className='btn btn-primary'>Invite</button>
            }
            <button onClick={deleteHandler} type={'button'} className='btn btn-secondary'>Delete</button>
          </div>
          <div className='vacation'>
            <p>Day off: {single.dayOff}</p>
            <p>Sick leave: {single.sickLeave}</p>
          </div>
        </div>
      }
      <Modal isOpen={showPopup} setClose={()=>setShowPopup(false)} id={'copy-link'}>
        <div className='popup-link'>
          <h3>Activating Link</h3>
          <div className='form-group'>
            <input type="text" disabled={true} value={`${config.frontUrl}/activation/${single?.hash}`}/>
          </div>
          <CopyToClipboard
            text={`${config.frontUrl}/activation/${single?.hash}`}
            onCopy={() => setCopied(true)}
          >
            <button className='btn btn-primary'>{isCopied ? 'Copied' : 'Copy'}</button>
          </CopyToClipboard>
        </div>
      </Modal>
    </>
  )
}