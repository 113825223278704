import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ROUTES } from "./routes";
import { useAppSelector } from "../store/hooks";
import { ROLES } from "../types/role";
import { selectRole } from "../store/auth/authSelector";
import AuthGuard from "./AuthGuard";
import Layout from "../layout/components/Layout";
import { Employees } from "../layout/views/Employees/All";
import { Deck } from "../layout/views/Deck";
import { Employee } from "../layout/views/Employees/Single";
import { NewEmployee } from "../layout/views/Employees/New";
import { Activation } from "../layout/views/Auth/Activation";
import { Projects } from "../layout/views/Projects/All";
import { Login } from "../layout/views/Auth/Login";
import { Tracker } from "../layout/views/Tracker";
import { Me } from "../layout/views/Employees/Me";
import { Weekend } from "../layout/views/Weekend";
import { NewProject } from "../layout/views/Projects/New";
import { Project } from "../layout/views/Projects/Single";
import { Tasks } from "../layout/views/Tasks/All";
import { NewTask } from "../layout/views/Tasks/New";
import { Companies } from "../layout/views/Companies/All";
import { NewCompany } from "../layout/views/Companies/New";
import { Industries } from "../layout/views/Industries/All";
import { NewIndustry } from "../layout/views/Industries/New";
import { Industry } from "../layout/views/Industries/Single";
import { Company } from "../layout/views/Companies/Single";
import { EditProject } from "../layout/views/Projects/Edit";

type GuardProps = {
  // eslint-disable-next-line
  child1: any,
  // eslint-disable-next-line
  child2?: any,
}

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path={ROUTES.login} element={<Login/>}/>
      <Route path={ROUTES.activation} element={<Activation/>}/>
      <Route element={<Layout/>}>
        <Route element={<AuthGuard/>}>
          <Route
            path={ROUTES.home}
            element={
              <RoleGuard child1={<Deck/>} child2={<Tracker/>}/>
            }
          />
          <Route
            path={ROUTES.tracker}
            element={
              <Tracker/>
            }
          />
          <Route
            path={ROUTES.employees}
            element={
              <Employees/>
            }
          />
          <Route
            path={ROUTES.projects}
            element={
              <Projects/>
            }
          />
          <Route
            path={ROUTES.singleEmployee}
            element={
              <Employee/>
            }
          />
          <Route
            path={ROUTES.singleProject}
            element={
              <Project/>
            }
          />
          <Route
            path={ROUTES.editProject}
            element={
              <EditProject/>
            }
          />
          <Route
            path={ROUTES.me}
            element={
              <Me/>
            }
          />
          <Route
            path={ROUTES.newEmployee}
            element={
              <NewEmployee/>
            }
          />
          <Route
            path={ROUTES.newProject}
            element={
              <NewProject/>
            }
          />
          <Route
            path={ROUTES.tasks}
            element={
              <Tasks/>
            }
          />
          <Route
            path={ROUTES.newTask}
            element={
              <NewTask/>
            }
          />
          <Route
            path={ROUTES.weekends}
            element={
              <Weekend/>
            }
          />
          <Route
            path={ROUTES.companies}
            element={
              <Companies/>
            }
          />
          <Route
            path={ROUTES.newCompany}
            element={
              <NewCompany/>
            }
          />
          <Route
            path={ROUTES.singleCompany}
            element={
              <Company/>
            }
          />
          <Route
            path={ROUTES.industries}
            element={
              <Industries/>
            }
          />
          <Route
            path={ROUTES.newIndustry}
            element={
              <NewIndustry/>
            }
          />
          <Route
            path={ROUTES.singleIndustry}
            element={
              <Industry/>
            }
          />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
)

const RoleGuard = ({ child1, child2 }: GuardProps) => {
  const role = useAppSelector(selectRole);
  return role === ROLES.MANAGER ? child1 : role === ROLES.USER && child2;
}