import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import { IWeekend, IWeekendCreate, IWeekendModal } from "../../types/weekend";

export const weekendApi = createApi({
  reducerPath: 'weekendApi',
  baseQuery: customFetchBase,
  tagTypes: ['Weekend'],
  endpoints: (builder) => ({
    weekends: builder.query<{data: IWeekend[]}, void>({
      query: () => ({
        url: 'weekends',
        method: 'GET'
      }),
    }),
    addWeekend: builder.mutation<IWeekendCreate, IWeekendModal>({
      query: (data) => ({
        url: 'weekends/new',
        method: 'POST',
        body: data
      }),
    }),
  }),
})

export const {
  useWeekendsQuery,
  useAddWeekendMutation
} = weekendApi
