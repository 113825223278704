import React, { FormEvent, useEffect, useState } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import eye from '../../../../../assets/images/view.png'
import { useNavigate, useParams } from "react-router-dom";
import {
  useActivateEmployeeMutation,
  useEmployeeByHashQuery
} from "../../../../store/employee/employeeApi";
import { ROUTES } from "../../../../routes/routes";
export const Activation = () => {

  const { single } = useAppSelector(state => state.employee);
  const [employeeData, setEmployeeData] = useState(single || null)
  const [showPassword, togglePassword] = useState(false)
  const [password, setPassword] = useState('')
  const [activate] = useActivateEmployeeMutation();
  const { hash } = useParams();
  const navigate = useNavigate();
  useEmployeeByHashQuery(String(hash));

  useEffect(()=>{
    if(single){
      setEmployeeData(single)
    }
  }, [single])

  const activateHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    activate({ hash: String(hash), password }).then(() => {
      navigate(ROUTES.login)
    })
  }

  const passwordToggle = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    togglePassword(!showPassword)
  }

  return (
    <div className='activation-wrapper'>
      {employeeData && employeeData.name &&
      <>
        <h3>{`Hi, ${employeeData.name}`}</h3>
        <form onSubmit={activateHandler}>
          <div className="form-group password-group">
            <label>Password</label>
            <input type={showPassword ? "text" : "password"} value={password} onChange={(e)=>setPassword(e.target.value)}/>
            <button onClick={passwordToggle}>
              <img src={eye} alt=""/>
            </button>
          </div>
          <button className='btn-primary'>Save</button>
        </form>
      </>
      }
    </div>
  )
}