import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from "../../types/auth";
import { authApi } from "./authApi";

const initialState: AuthState = {
  token: null,
  role: null,
  user: null,
  isLoading: false,
  isAuthenticated: false,
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null
      state.role = null
      state.user = null
      localStorage.setItem('userToken', '');
      localStorage.setItem('userRole', '');
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.isLoading = false
        state.isAuthenticated = true
        state.token = payload.token
        state.role = payload.role
        localStorage.setItem('userToken', payload.token)
        localStorage.setItem('userRole', payload.role);
      }
    )
    builder.addMatcher(
      authApi.endpoints.me.matchFulfilled,
      (state, { payload }) => {
        state.user = payload.data
      }
    )
    builder.addMatcher(
      authApi.endpoints.login.matchPending,
      (state) => {
        state.isLoading = true
        state.isAuthenticated = false
      }
    )
    builder.addMatcher(
      authApi.endpoints.login.matchPending,
      (state) => {
        state.isLoading = false
        state.isAuthenticated = false
      }
    )
  },
});
export const { logout } = slice.actions

export default slice.reducer;
