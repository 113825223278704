import React, { useEffect, useState } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import eye from '../../../../../assets/images/view.png'
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../../../types/role";
import { useLoginMutation } from "../../../../store/auth/authApi";
import { Loader } from "../../../components/Loader";
import { ROUTES } from "../../../../routes/routes";

export const Login = () => {

  const { isAuthenticated, role } = useAppSelector(state => state.auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, togglePassword] = useState(false)
  const [login, { isError, isLoading }] = useLoginMutation();
  const navigate = useNavigate();

  const passwordToggle = () => {
    togglePassword(!showPassword)
  }

  const loginHandler = (e:React.FormEvent) => {
    e.preventDefault();
    login({ email, password })
  }

  useEffect(()=>{
    if (isAuthenticated && role === ROLES.MANAGER) {
      navigate(ROUTES.home)
    }
    else if(isAuthenticated && role === ROLES.USER){
      navigate(ROUTES.tracker)
    }
  }, [isAuthenticated])

  return (
    <>
      <Loader isLoading={isLoading}/>
      <div className='login-wrapper'>
        <h3>Login page</h3>
        <form onSubmit={loginHandler}>
          <div className="form-group">
            <label>Email</label>
            <input type={"email"} value={email} onChange={(e)=>setEmail(e.target.value)}/>
          </div>
          <div className="form-group password-group">
            <label>Password</label>
            <input type={showPassword ? "text" : "password"} value={password} onChange={(e)=>setPassword(e.target.value)}/>
            <button onClick={passwordToggle} type={'button'}>
              <img src={eye} alt=""/>
            </button>
          </div>
          <button className='btn-primary' type={'submit'}>Login</button>
          {isError &&
            <p className='error-text'>Invalid credentials</p>
          }
        </form>
      </div>
    </>
  )
}