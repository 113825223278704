import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import FullCalendar, { DateSelectArg } from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceCommon from '@fullcalendar/resource-common';
import interaction from '@fullcalendar/interaction';
import { useAppSelector } from "../../../store/hooks";
import {
  eventDrop,
  eventMount,
  eventResize,
  resourceContent,
  scrollToToday,
} from "./helpers";
import { EditModal } from "./components/EditModal";
import { AddModal } from "./components/AddModal";
import { useEventsQuery } from "../../../store/event/eventApi";
import { useEmployeesForEventsQuery } from "../../../store/employee/employeeApi";
import { Loader } from "../../components/Loader";
import { IEventModal } from "../../../types/event";

export const Deck = () => {
  const { employeesEvents } = useAppSelector(state => state.employee);
  const { events } = useAppSelector(state => state.event);
  const [isLoad, setLoading] = useState(true);
  const [isOpenAdd, setOpenAdd] = useState(false);
  const [isOpenEdit, setOpenEdit] = useState(false);
  const [employee, setEmployee] = useState('');
  const [initialValues, setInitialValues] = useState({ dateStart: '', dateEnd: '' });
  const [editValues, setEditValues] = useState<IEventModal | undefined>();
  const calendarRef = useRef<FullCalendar>(null);
  const { isLoading: isLoadingEvents } = useEventsQuery();
  useEmployeesForEventsQuery();

  useEffect(()=>{
    scrollToToday()
    setTimeout(()=>{
      setLoading(false)
    },2500)
  },[])

  const selectAction = ( info: DateSelectArg) => {
    setOpenAdd(true)
    setEmployee(info.resource?.id ?? '');
    setInitialValues({
      dateStart: info.startStr,
      dateEnd: info.endStr
    })
  }

  const editHandler = () => {
    setOpenEdit(true)
  }

  return (
    <>
      <div className='deck-wrapper'>
        <Loader isLoading={isLoad} isDeck/>
        {events && employeesEvents && events.length > 0 && !isLoadingEvents &&
        <FullCalendar
          plugins={[resourceTimelinePlugin, resourceCommon, interaction]}
          initialView='myView'
          views={{
            myView: {
              type: 'resourceTimelineMonth',
              duration: { months: 3 },
              slotDuration: { days: 1 }
            }
          }}
          slotLabelFormat={[
            { weekday: 'short', omitCommas: true },
            { day: 'numeric', month: 'numeric' }
          ]}
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'prev,next'
          }}
          schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
          editable={true}
          selectable={true}
          droppable={true}
          height='auto'
          resourceAreaWidth={'25%'}
          slotMinWidth={50}
          resourceAreaHeaderContent={'Employees'}
          resources={employeesEvents}
          events={events}
          resourceOrder={'sortOrder'}
          resourceGroupField={'roleName'}
          resourceAreaColumns={resourceContent}
          eventDidMount={({ event, el })=>eventMount({ event, el, editHandler, setEditValues })}
          select={selectAction}
          eventResize={eventResize}
          eventDrop={eventDrop}
          ref={calendarRef}
        />
        }
      </div>
      <EditModal
        calendar={calendarRef}
        isOpen={isOpenEdit}
        setClose={()=>setOpenEdit(false)}
        values={editValues}
      />
      <AddModal
        calendar={calendarRef}
        isOpen={isOpenAdd}
        setClose={()=>setOpenAdd(false)}
        employee={employee}
        initialValues={initialValues}
      />
    </>
  )
}