import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import FullCalendar from '@fullcalendar/react'
import { useAppSelector } from "../../../store/hooks";
import { AddModal } from "./components/AddModal";
import { Loader } from "../../components/Loader";
import { useMeQuery } from "../../../store/auth/authApi";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Calendar from 'rc-year-calendar';
import { IWeekendModal, IWeekendShow } from "../../../types/weekend";
import { addYears, endOfYear, format, startOfYear } from "date-fns";
import { useWeekendsQuery } from "../../../store/weekend/weekendApi";
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';
import { isManager } from "../../../store/auth/authSelector";

export const Weekend = () => {

  const { weekends } = useAppSelector(state => state.weekend);
  const manager = useAppSelector(isManager);
  const calendarRef = useRef<FullCalendar>(null);
  const [isOpen, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<IWeekendModal>();
  const [weekendsData, setWeekendsData] = useState<IWeekendShow[]>();
  const { isLoading, refetch } = useWeekendsQuery();
  useMeQuery();

  const dayHandler = ({ date }:{date: Date}) => {
    if(manager) {
      setInitialValues({
        dateStart: format(date, 'yyyy-MM-dd'),
        dateEnd: format(date, 'yyyy-MM-dd'),
      })
      setOpen(true)
    }
  }

  const rangeHandler = ({ startDate, endDate }:{startDate: Date, endDate: Date}) => {
    if(manager) {
      setInitialValues({
        dateStart: format(startDate, 'yyyy-MM-dd'),
        dateEnd: format(endDate, 'yyyy-MM-dd'),
      })
      setOpen(true)
    }
  }

  const hoverHandler = ({ element, events }:{element: HTMLElement,events:IWeekendShow[]}) => {
    if(events && events.length > 0) {
      tippy(element, {
        content: events[0].name,
        placement: 'top',
        animation: 'shift-away',
        showOnCreate: true
      });
    }
  }

  useEffect(()=>{
    if(weekends){
      setWeekendsData(weekends.map((weekend, index) => ({
        id: index,
        name: weekend.title,
        startDate: new Date(weekend.start),
        endDate: new Date(weekend.end)
      })))
    }
  }, [weekends])

  const refetchWeekends = () => {
    refetch()
  }

  return (
    <>
      <div className='deck-wrapper weekend-wrapper'>
        <Loader isLoading={isLoading}/>
        {weekendsData &&
          <Calendar
            style="background"
            minDate={startOfYear(new Date())}
            maxDate={endOfYear(addYears(new Date(), 1))}
            onDayClick={dayHandler}
            onRangeSelected={rangeHandler}
            enableRangeSelection={manager}
            dataSource={weekendsData}
            onDayEnter={hoverHandler}
            weekStart={1}
          />
        }
      </div>
      <AddModal calendar={calendarRef} isOpen={isOpen} setClose={()=>setOpen(false)} refetch={refetchWeekends} initialValues={initialValues}/>
    </>
  )
}