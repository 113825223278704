import React, { useEffect } from 'react'
import '../New/style.scss'
import { IProjectFull } from "../../../../types/project";
import {
  useChangeProjectMutation,
  useSingleProjectQuery
} from "../../../../store/project/projectApi";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../routes/routes";
import { ProjectForm } from "../components/ProjectForm";
import { useAppSelector } from "../../../../store/hooks";
import { Loader } from "../../../components/Loader";

export const EditProject = () => {

  const { single } = useAppSelector(state => state.project);
  const [editProject, { isSuccess }] = useChangeProjectMutation()
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading } = useSingleProjectQuery(String(id))

  const onSubmit = (data: IProjectFull) => {
    editProject(data)
  }

  useEffect(()=>{
    if(isSuccess){
      navigate(ROUTES.projects)
    }
  }, [isSuccess])

  return (
    <>
      <Loader isLoading={isLoading}/>
      <div className='new-project-wrapper'>
        <h3>Edit project</h3>
        <ProjectForm onSubmit={onSubmit} isEdit={true} initialValues={single ?? undefined}/>
      </div>
    </>
  )
}