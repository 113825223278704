import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import { IProject, IProjectFull, IProjectResponse } from "../../types/project";

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: customFetchBase,
  tagTypes: ['Project'],
  endpoints: (builder) => ({
    projects: builder.query<IProjectResponse, {page: number}>({
      query: (data) => ({
        url: `projects/filter/${data.page}/10`,
        method: 'GET'
      }),
      providesTags: ['Project']
    }),
    projectsForEvent: builder.query<{data: IProject[]}, void>({
      query: () => ({
        url: `projects/for-event`,
        method: 'GET'
      }),
    }),
    addProject: builder.mutation<IProjectFull, Omit<IProjectFull, 'id'>>({
      query: (data) => ({
        url: `projects`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Project']
    }),
    singleProject: builder.query<{data: IProjectFull}, string>({
      query: (id) => ({
        url: `projects/${id}`,
        method: 'GET'
      }),
      providesTags: ['Project']
    }),
    changeProject: builder.mutation<void, IProjectFull>({
      query: (data) => ({
        url: `projects/${data.id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Project']
    }),
    deleteProject: builder.mutation<void, string>({
      query: (id) => ({
        url: `projects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Project']
    }),
  }),
})

export const {
  useProjectsQuery,
  useLazyProjectsQuery,
  useProjectsForEventQuery,
  useAddProjectMutation,
  useSingleProjectQuery,
  useDeleteProjectMutation,
  useChangeProjectMutation
} = projectApi
