import { createSlice } from '@reduxjs/toolkit';
import { weekendApi } from "./weekendApi";
import { WeekendState } from "../../types/weekend";

const initialState: WeekendState = {
  weekends: null,
};

export const slice = createSlice({
  name: 'weekend',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      weekendApi.endpoints.weekends.matchFulfilled,
      (state, { payload }) => {
        state.weekends = payload.data
      }
    )
  },
});

export default slice.reducer;
