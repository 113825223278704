import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { trackerApi } from "./trackerApi";
import { IEntry, TrackerState } from "../../types/tracker";

const initialState: TrackerState = {
  entries: null,
  single: null,
  active: null
};

export const slice = createSlice({
  name: 'tracker',
  initialState,
  reducers: {
    setActiveEntry: (state, { payload }: PayloadAction<IEntry | null>) => {
      state.active = payload
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      trackerApi.endpoints.entriesByEmployee.matchFulfilled,
      (state, { payload }) => {
        state.entries = payload.data
      }
    )
    builder.addMatcher(
      trackerApi.endpoints.addEntry.matchFulfilled,
      (state, { payload }) => {
        state.single = payload.data
      }
    )
    builder.addMatcher(
      trackerApi.endpoints.changeEntry.matchFulfilled,
      (state) => {
        state.single = null
      }
    )
  },
});

export const { setActiveEntry } = slice.actions

export default slice.reducer;
