import { createSlice } from '@reduxjs/toolkit';
import { companyApi } from "./companyApi";
import { CompanyState } from "../../types/company";

const initialState: CompanyState = {
  companies: null,
  single: null
};

export const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      companyApi.endpoints.companies.matchFulfilled,
      (state, { payload }) => {
        state.companies = payload.data
      }
    )
    builder.addMatcher(
      companyApi.endpoints.singleCompany.matchFulfilled,
      (state, { payload }) => {
        state.single = payload.data
      }
    )
  },
});

export default slice.reducer;
