import { createSlice } from '@reduxjs/toolkit';
import { projectApi } from "./projectApi";
import { ProjectState } from "../../types/project";

const initialState: ProjectState = {
  projects: null,
  total: 0,
  projectsEvent: null,
  single: null,
};

export const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      projectApi.endpoints.projects.matchFulfilled,
      (state, { payload }) => {
        state.projects = payload.data.projects
        state.total = payload.data.total
      }
    )
    builder.addMatcher(
      projectApi.endpoints.projectsForEvent.matchFulfilled,
      (state, { payload }) => {
        state.projectsEvent = payload.data
      }
    )
    builder.addMatcher(
      projectApi.endpoints.singleProject.matchFulfilled,
      (state, { payload }) => {
        state.single = payload.data
      }
    )
  },
});

export default slice.reducer;
