import format from "date-fns/format";
import { differenceInSeconds, intervalToDuration, parseISO } from "date-fns";
import React from "react";
import { IEntry } from "../../../../types/tracker";
import { zeroPad } from "../../../../utils/helpers";

const TrackerResultItem = ({ item }:{item: IEntry}) => {

  let formattedTotal;
  if(item.dateFinished) {
    const duration = intervalToDuration({
      start: 0,
      end: differenceInSeconds(parseISO(item.dateFinished), parseISO(item.dateStarted)) * 1000
    })
    formattedTotal = `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`
  }

  return (
    <div className='tracker-result-item'>
      <p>Project: <span>{item.project && item.task ? item.project.projectName + ' (' + item.task.name + ')' : item.project ? item.project.projectName : 'No project'}</span></p>
      <p>Description: <span>{item.description}</span></p>
      <p>Start: <span>{format(parseISO(item.dateStarted.slice(0, -6)), 'HH:mm:ss')}</span></p>
      <p>Finished: <span>{item.dateFinished ? format(parseISO(item.dateFinished.slice(0, -6)), 'HH:mm:ss') : 'Not finished yet'}</span></p>
      {item.dateFinished ?
        <p>Total: <span>{formattedTotal}</span></p>
        :
        <p/>
      }
    </div>
  )
}

export default TrackerResultItem