import React, { useEffect, useState } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { IEmployee } from "../../../../types/employee";
import { Link } from "react-router-dom";
import { useRolesQuery } from "../../../../store/role/roleApi";
import { ROLES } from "../../../../types/role";
import { selectRole } from "../../../../store/auth/authSelector";
import { ROUTES } from "../../../../routes/routes";
import { useEmployeesQuery } from "../../../../store/employee/employeeApi";

export const Employees = () => {

  const role = useAppSelector(selectRole);
  const { employees } = useAppSelector(state => state.employee);
  const { roles } = useAppSelector(state => state.role);
  const [employeesFilter, setEmployeesFilter] = useState<IEmployee[]>(employees ? employees : [])
  const { isLoading } = useEmployeesQuery();
  useRolesQuery();

  useEffect(()=>{
    if(employees && employees.length > 0){
      setEmployeesFilter(employees)
    }
  }, [employees])

  const filterHandler = (role: string) => {
    if(employees) {
      setEmployeesFilter(employees.filter(employee => {
        if (role !== 'all') {
          return employee.role.name === role;
        } else {
          return employee
        }
      }));
    }
  }

  return (
    <>
      {employeesFilter.length > 0 && !isLoading &&
        <div className='employees-wrapper'>
          <div className='filter'>
            <button onClick={()=>filterHandler('all')}>All</button>
            {roles && roles.map((role, index) => (
              <button onClick={()=>filterHandler(role.name)} key={index}>{role.name}</button>
            ))}
          </div>
          {employeesFilter.map((employee, index) => (
            <Link to={'/employee/' + employee.id} className='employee-item' key={index}>
              {role === ROLES.MANAGER &&
                <div className={employee.isActivated ? 'activated' : 'not-activated'}/>
              }
              {employee.image ?
                <img src={employee.image} alt=""/>
                :
                <div className='no-image'>N/A</div>
              }
              <h3>{employee.name}</h3>
              <div className='vacation'>
                <p>Day off: {employee.dayOff}</p>
                <p>Sick leave: {employee.sickLeave}</p>
              </div>
            </Link>
          ))}
        </div>
      }
      <Link to={ROUTES.newEmployee} className='add-new'>+</Link>
    </>
  )
}