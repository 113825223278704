export interface RolesState {
  roles: null | IRole[];
}

export interface IRole {
  id: number
  name: string
}

export const ROLES = {
  USER: 'ROLE_USER',
  MANAGER: 'ROLE_MANAGER'
}