import React, { useEffect } from 'react'
import './style.scss'
import { useForm } from "react-hook-form";
import Input from "../../../components/Input";
import { useNavigate, useParams } from "react-router-dom";
import { ITask } from "../../../../types/task";
import { useAddTaskMutation } from "../../../../store/task/taskApi";

export const NewTask = () => {

  const [addTask, { isSuccess }] = useAddTaskMutation();
  const { register, handleSubmit, formState: { errors } } = useForm<Omit<ITask, 'id'>>();
  const navigate = useNavigate();
  const { id } = useParams();

  const onSubmit = (data: Omit<ITask, 'id'>) => {
    addTask({
      ...data,
      project: String(id)
    })
  }

  useEffect(()=>{
    if(isSuccess){
      navigate(`/tasks/${id}`)
    }
  }, [isSuccess])

  return (
    <div className='new-project-wrapper'>
      <h3>Add task</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type={'text'} label={'Task name'} register={register} name={'name'} errors={errors} options={{ required: true }}/>
        <Input type={'number'} label={'Hours'} register={register} name={'hours'} errors={errors}/>
        <button className='btn btn-primary' type={'submit'}>Create</button>
      </form>
    </div>
  )
}