import React, { useEffect, useState } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import { IProjectFull } from "../../../../types/project";
import { useLazyProjectsQuery } from "../../../../store/project/projectApi";
import { ROUTES } from "../../../../routes/routes";
import { Loader } from "../../../components/Loader";

export const Projects = () => {
  const [page, setPage] = useState(0);
  const { projects, total } = useAppSelector(state => state.project);
  const [projectsData, setProjectsData] = useState<IProjectFull[]>(projects ? projects : [])
  const [getProjects, { isLoading }] = useLazyProjectsQuery();
  const navigate = useNavigate();

  useEffect(()=>{
    getProjects({ page: Number(page) })
  }, [page]);

  useEffect(()=>{
    if(projects && projects.length > 0){
      setProjectsData(projects)
    }
  }, [projects])

  const onPrevious = () => {
    setPage(page - 10)
  }

  const onNext = () => {
    setPage(page + 10)
  }

  return (
    <>
      <Loader isLoading={isLoading}/>
      {projectsData.length > 0 && !isLoading &&
      <div className='projects-wrapper'>
        <div className='projects-header'>
          <p>Title</p>
          <p>Status</p>
          <p>Estimated hours</p>
          <p>Actual hours</p>
          <p>Color</p>
          <p>Is internal?</p>
          <p>Actions</p>
        </div>
        {projectsData.map((project, index) => (
          <div
            // onClick={()=>navigate('/project/' + project.id)}
            className='project-item'
            key={index}
          >
            <h3>{project.number} {project.projectName}</h3>
            <p className='status'>{project.status}</p>
            <p className='estimatedHours'>{project.estimatedHours}</p>
            <p className='actualHours'>{project.actualHours}</p>
            <div className='color' style={{ backgroundColor: project.color }}/>
            <div className={project.isInternal ? 'internal active' : 'internal' }/>
            <div className='buttons'>
              <button onClick={()=>navigate(`/tasks/${project.id}`)}>Tasks</button>
              <button onClick={()=>navigate(`/project/${project.id}`)}>Show</button>
              <button onClick={()=>navigate(`/project/edit/${project.id}`)}>Edit</button>
            </div>
          </div>
        ))}
        <div className='pagination'>
          <button onClick={onPrevious} disabled={page===0}>Prev</button>
          <p>{(page/10) + 1}</p>
          <button onClick={onNext} disabled={page>=total-10}>Next</button>
        </div>
      </div>
      }
      <Link to={ROUTES.newProject} className='add-new'>+</Link>
    </>
  )
}