import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import {
  IEmployee,
  IEmployeeActivate,
  IEmployeeEvent,
  IEmployeeSlack,
  INewEmployee
} from "../../types/employee";

export const employeeApi = createApi({
  reducerPath: 'employeeApi',
  baseQuery: customFetchBase,
  tagTypes: ['Employee'],
  endpoints: (builder) => ({
    employeesForEvents: builder.query<{data: IEmployeeEvent[]}, void>({
      query: () => ({
        url: 'employees/for-events',
        method: 'GET'
      }),
    }),
    employeesSlack: builder.query<{data: IEmployeeSlack[]}, void>({
      query: () => ({
        url: 'employees/slack',
        method: 'GET'
      }),
    }),
    employees: builder.query<{data: IEmployee[]}, void>({
      query: () => ({
        url: 'employees',
        method: 'GET'
      }),
      providesTags: ['Employee']
    }),
    employeeByHash: builder.query<{data: IEmployee}, string>({
      query: (hash) => ({
        url: `employees/by-hash/${hash}`,
        method: 'GET'
      }),
    }),
    singleEmployee: builder.query<{data: IEmployee}, string>({
      query: (id) => ({
        url: `employees/${id}`,
        method: 'GET'
      }),
    }),
    addEmployee: builder.mutation<INewEmployee, Omit<IEmployee, 'id'>>({
      query: (data) => ({
        url: `employees`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Employee']
    }),
    activateEmployee: builder.mutation<INewEmployee, IEmployeeActivate>({
      query: (data) => ({
        url: `employees/activate/${data.hash}`,
        method: 'POST',
        body: {
          password: data.password
        }
      }),
    }),
    inviteEmployee: builder.mutation<{data: INewEmployee}, string>({
      query: (id) => ({
        url: `employees/invite/${id}`,
        method: 'POST',
      }),
    }),
    deleteEmployee: builder.mutation<void, string>({
      query: (id) => ({
        url: `employees/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Employee']
    }),
  }),
})

export const {
  useEmployeesForEventsQuery,
  useEmployeesQuery,
  useSingleEmployeeQuery,
  useLazySingleEmployeeQuery,
  useEmployeesSlackQuery,
  useAddEmployeeMutation,
  useEmployeeByHashQuery,
  useActivateEmployeeMutation,
  useInviteEmployeeMutation,
  useDeleteEmployeeMutation
} = employeeApi
