import React, { RefObject, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import Modal from "../../../../components/Modal";
import { IEventChange, IEventModal } from "../../../../../types/event";
import { useForm } from "react-hook-form";
import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import { hours } from "../../constants";
import { useChangeEventMutation } from "../../../../../store/event/eventApi";
import { format, parseISO } from "date-fns";

type ModalProps = {
  calendar: RefObject<FullCalendar>,
  isOpen: boolean
  setClose: () => void
  values: IEventModal | undefined
}

export const EditModal = ({ calendar, isOpen, setClose, values }:ModalProps) => {

  const { register, handleSubmit, formState: { errors }, reset } = useForm<IEventChange>();
  const [changeEvent, { isLoading }] = useChangeEventMutation()

  useEffect(()=>{
    if(isOpen && values?.dateStart && values.dateEnd) {
      reset({
        ...values,
        dateStart: format(parseISO(values.dateStart), 'yyyy-MM-dd'),
        dateEnd: format(parseISO(values.dateEnd), 'yyyy-MM-dd')
      })
    }
  }, [isOpen])

  const editEventHandler = (data: IEventChange) => {
    const calendarApi = calendar.current?.getApi()
    const eventId = String(values?.id)

    changeEvent({
      ...data
    }).unwrap().then(()=>{
      setClose && setClose()
      const eventOb = calendarApi?.getEventById(eventId);
      eventOb?.setStart(new Date(data.dateStart))
      eventOb?.setEnd(new Date(data.dateEnd))
      // eslint-disable-next-line
      document.querySelector('[data-id="' + eventId + '"]')!
        .querySelector('.fc-event-title-container span')!
        .textContent = " - " + data.hours.toString() + "h";
      eventOb?.setExtendedProp('hours', data.hours);
    })
  }

  return (
    <Modal isOpen={isOpen} setClose={setClose} id='edit-event'>
      <form onSubmit={handleSubmit(editEventHandler)}>
        <Input type={'text'} register={register} name={'project'} errors={errors} label={'Project'} disabled/>
        <Input type={'text'} register={register} name={'creator'} errors={errors} label={'Who created'} disabled/>
        <Input type={'date'} register={register} name={'dateStart'} errors={errors} label={'Date start'}/>
        <Input type={'date'} register={register} name={'dateEnd'} errors={errors} label={'Date start'}/>
        <Select register={register} name={'hours'} errors={errors} values={hours} label={'Hours'}/>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>Save changes</button>
      </form>
    </Modal>
  )
}