import React from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { useCompaniesQuery } from "../../../../store/company/companyApi";
import { Loader } from "../../../components/Loader";
import { ROUTES } from "../../../../routes/routes";
import { Link } from "react-router-dom";

export const Companies = () => {

  const { companies } = useAppSelector(state => state.company);
  const { isLoading } = useCompaniesQuery()

  return (
    <>
      <Loader isLoading={isLoading}/>
      {companies && companies.length > 0 &&
        <div className='companies-wrapper'>
          <h3>Companies list</h3>
          <div className='companies-header'>
            <h5>Name</h5>
            <h5>Total projects</h5>
          </div>
          {companies.map(company => (
            <Link to={`/company/${company.id}`} key={company.id} className='company-item'>
              <p>{company.name}</p>
              <p>{company.total}</p>
            </Link>
          ))}
        </div>
      }
      <Link to={ROUTES.newCompany} className='add-new'>+</Link>
    </>
  )
}