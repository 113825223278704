import React from 'react'
import Select from 'react-select'

type SelectProps = {
  label?: string,
  value?: any,
  inputRef?: any
  isMultiple?: boolean
  values: any
  onChange?: (s: any) => void
}

export const CustomSelect = (props: SelectProps) => {

  const { label, values, isMultiple, inputRef, value, onChange } = props;

  return (
    <div className='form-group'>
      {label &&
        <label>{label}</label>
      }
      <Select
        options={values}
        ref={inputRef}
        isMulti={isMultiple}
        value={value}
        onChange={onChange}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: 'black',
            borderRadius: 0,
            minHeight: 43
          }),
        }}
      />
    </div>
  )
}