import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import { IEvent, IEventAdd, IEventChange, IEventModal } from "../../types/event";

export const eventApi = createApi({
  reducerPath: 'eventApi',
  baseQuery: customFetchBase,
  tagTypes: ['Event'],
  endpoints: (builder) => ({
    events: builder.query<{data: IEvent[]}, void>({
      query: () => ({
        url: 'events',
        method: 'GET'
      }),
    }),
    singleEvent: builder.mutation<{data: IEvent}, {id: string}>({
      query: (id) => ({
        url: `events/${id}`,
        method: 'GET'
      }),
    }),
    addEvent: builder.mutation<IEventAdd, IEventModal>({
      query: (data) => ({
        url: `events/new`,
        method: 'POST',
        body: data
      }),
    }),
    changeEvent: builder.mutation<[], IEventChange>({
      query: (data) => ({
        url: `events/change`,
        method: 'PATCH',
        body: data
      }),
    }),
  }),
})

export const {
  useEventsQuery,
  useSingleEventMutation,
  useAddEventMutation,
  useChangeEventMutation
} = eventApi
