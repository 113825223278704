import React, { FormEvent, useEffect, useState } from "react";
import { useProjectsForEventQuery } from "../../../../store/project/projectApi";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useAddEntryMutation, useChangeEntryMutation } from "../../../../store/tracker/trackerApi";
import { Controller, useForm } from "react-hook-form";
import { IEntryCreate } from "../../../../types/tracker";
import format from "date-fns/format";
import { setActiveEntry } from "../../../../store/tracker/trackerSlice";
import { differenceInMilliseconds } from "date-fns";
import { msToTime, transformArrayToSelect } from "../../../../utils/helpers";
import { useLazyTasksByProjectQuery } from "../../../../store/task/taskApi";
import { CustomSelect } from "../../../components/CustomSelect";
import { ISelect } from "../../../../types/project";

type TimerProps = {
  employeeId: number;
  onChange: () => void
}
const TrackerTimer = ({ employeeId, onChange }:TimerProps) => {

  const { projectsEvent } = useAppSelector(state => state.project);
  const { tasks } = useAppSelector(state => state.task);
  const { active } = useAppSelector(state => state.tracker);
  const { register, handleSubmit, watch, control } = useForm<IEntryCreate>();
  const [isActive, setIsActive] = useState(!!active);
  const [projectValues, setProjectValues] = useState<ISelect[]>([]);
  const [taskValues, setTaskValues] = useState<ISelect[]>([]);
  const [time, setTime] = useState(active ? differenceInMilliseconds(new Date(), new Date(active.dateStarted)) : 0);
  const dispatch = useAppDispatch();
  useProjectsForEventQuery();
  const [addEntry] = useAddEntryMutation()
  const [changeEntry] = useChangeEntryMutation()
  const [fetchTasks] = useLazyTasksByProjectQuery();

  useEffect(()=>{
    if(projectsEvent){
      setProjectValues(transformArrayToSelect(projectsEvent, 'id', 'name'))
    }
  }, [projectsEvent])

  useEffect(()=>{
    if(tasks){
      setTaskValues(transformArrayToSelect(tasks, 'id', 'name'))
    }
  }, [tasks])

  useEffect(() => {
    let interval = 0;
    if (isActive) {
      interval = window.setInterval(() => {
        setTime((time) => time + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive]);

  const startHandler = (data: IEntryCreate) => {
    addEntry({
      dateStarted: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      project: data.project,
      task: data.task,
      employee: employeeId,
      description: data.description
    }).unwrap().then((res)=>{
      if(res.data) {
        dispatch(setActiveEntry({
          ...res.data,
          dateStarted: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        }))
      }
    })
    setIsActive(true);
  }

  const stopHandler = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    changeEntry({
      id: String(active?.id),
      data: {
        dateFinished: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      }
    }).unwrap().then(()=>{
      onChange()
      dispatch(setActiveEntry(null))
    })
    setTime(0);
    setIsActive(false);
  }

  return (
    <form onSubmit={handleSubmit(startHandler)} className='timer-header'>
      <div className='form-group'>
        <input type="text" {...register('description')} placeholder={'Description'}/>
      </div>
      <Controller
        control={control}
        name="project"
        render={({ field: { onChange, value, ref } }) => (
          <CustomSelect
            inputRef={ref}
            value={value ? projectValues.find(c => c.value === value) : undefined}
            onChange={(e)=>{
              fetchTasks(e.value)
              onChange(e.value)
            }}
            values={projectValues}
          />
        )}
      />
      {watch().project ?
        <Controller
          control={control}
          name="task"
          render={({ field: { onChange, value, ref } }) => (
            <CustomSelect
              inputRef={ref}
              value={value ? taskValues.find(c => c.value === value) : undefined}
              onChange={(e)=>onChange(e.value)}
              values={taskValues}
            />
          )}
        />
        :
        <></>
      }
      {isActive ?
        <button className='btn btn-secondary' type={'button'} onClick={stopHandler}>Stop</button>
        :
        <button className='btn btn-primary' type={'submit'}>Start</button>
      }
      <div className="timer">
        <span className="digits">
          {msToTime(time)}
        </span>
      </div>
    </form>
  )
}

export default TrackerTimer