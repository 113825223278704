import React, { RefObject, useEffect } from "react";
import Modal from "../../../../components/Modal";
import FullCalendar from "@fullcalendar/react";
import { IWeekendModal } from "../../../../../types/weekend";
import Input from "../../../../components/Input";
import { useForm } from "react-hook-form";
import { useAddWeekendMutation } from "../../../../../store/weekend/weekendApi";

type ModalProps = {
  calendar: RefObject<FullCalendar>,
  isOpen: boolean
  setClose: () => void
  refetch: () => void
  initialValues: IWeekendModal | undefined
}

export const AddModal = ({ calendar, setClose, isOpen, initialValues, refetch }: ModalProps) => {

  const { register, handleSubmit, formState: { errors }, reset } = useForm<IWeekendModal>();
  const [addWeekend, { isLoading }] = useAddWeekendMutation()

  useEffect(()=>{
    if(isOpen){
      reset(initialValues)
    }
  }, [isOpen])

  const addEventHandler = (data: IWeekendModal) => {
    const calendarApi = calendar.current?.getApi()
    addWeekend({
      ...data,
      'dateStart': data.dateStart + 'T00:00:00',
      'dateEnd': data.dateEnd + 'T00:00:00'
    }).unwrap().then((response)=>{
      calendarApi?.addEvent({
        id: response.data.id,
        title: data.title,
        start: data.dateStart,
        end: data.dateEnd,
      })
      reset()
      setClose && setClose()
      refetch && refetch()
    })
  }

  return (
    <Modal isOpen={isOpen} setClose={setClose} id={'add-weekend'}>
      <form onSubmit={handleSubmit(addEventHandler)}>
        <Input type={'text'} register={register} name={'title'} errors={errors} label={'Title'}/>
        <Input type={'date'} register={register} name={'dateStart'} errors={errors} label={'Date start'}/>
        <Input type={'date'} register={register} name={'dateEnd'} errors={errors} label={'Date end'}/>
        <button className="btn btn-primary" disabled={isLoading}>Save changes</button>
      </form>
    </Modal>
  )
}