export const ROUTES = {
  home: '/',
  login: '/login',
  me: '/me',
  tracker: '/tracker',
  activation: '/activation/:hash',
  weekends: '/weekends',
  employees: '/employees',
  singleEmployee: '/employee/:id',
  newEmployee: '/employee/new',
  projects: '/projects',
  newProject: '/project/new',
  singleProject: '/project/:id',
  editProject: '/project/edit/:id',
  newTask: '/task/new/:id',
  tasks: '/tasks/:id',
  newCompany: '/company/new',
  companies: '/companies',
  singleCompany: '/company/:id',
  newIndustry: '/industry/new',
  industries: '/industries',
  singleIndustry: '/industry/:id',
}