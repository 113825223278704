import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import { ICompany } from "../../types/company";

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: customFetchBase,
  tagTypes: ['Company'],
  endpoints: (builder) => ({
    companies: builder.query<{data: ICompany[]}, void>({
      query: () => ({
        url: 'companies',
        method: 'GET'
      }),
      providesTags: ['Company'],
    }),
    singleCompany: builder.query<{data: ICompany}, string>({
      query: (id) => ({
        url: `companies/${id}`,
        method: 'GET'
      }),
    }),
    addCompany: builder.mutation<void, Pick<ICompany, 'name'>>({
      query: (data) => ({
        url: `companies`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Company']
    }),
    changeCompany: builder.mutation<void, ICompany>({
      query: (data) => ({
        url: `companies/${data.id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Company']
    }),
    deleteCompany: builder.mutation<void, string>({
      query: (id) => ({
        url: `companies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company']
    }),
  }),
})

export const {
  useCompaniesQuery,
  useAddCompanyMutation,
  useChangeCompanyMutation,
  useDeleteCompanyMutation,
  useSingleCompanyQuery
} = companyApi
