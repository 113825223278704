import React, { useEffect } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import { ROUTES } from "../../../../routes/routes";
import { useDeleteCompanyMutation, useSingleCompanyQuery } from "../../../../store/company/companyApi";

export const Company = () => {
  const { single } = useAppSelector(state => state.company);
  const { id } = useParams();
  const { isLoading } = useSingleCompanyQuery(String(id));
  const [deleteCompany, { isSuccess }] = useDeleteCompanyMutation();
  const navigate = useNavigate();

  const deleteHandler = () => {
    deleteCompany(String(id))
  }

  useEffect(()=>{
    if(isSuccess)
      navigate(ROUTES.companies)
  }, [isSuccess])

  return (
    <>
      <Loader isLoading={isLoading}/>
      {single && !isLoading &&
      <div className='single-company'>
        <div className='row'>
          <h4>Company name</h4>
          <p>{single.name}</p>
        </div>
        {single.projects && single.projects.length > 0 &&
          <div className='single-company-projects'>
            <h4>Projects</h4>
            {single.projects.map(project => (
              <Link to={`/project/${project.id}`} key={project.id}>
                <p>{project.projectName}</p>
              </Link>
            ))}
          </div>
        }
        <button className='btn-secondary' onClick={deleteHandler}>Delete</button>
      </div>
      }
    </>
  )
}