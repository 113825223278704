import React, { useEffect } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import { useDeleteIndustryMutation, useSingleIndustryQuery } from "../../../../store/industry/industryApi";
import { ROUTES } from "../../../../routes/routes";

export const Industry = () => {
  const { single } = useAppSelector(state => state.industry);
  const { id } = useParams();
  const { isLoading } = useSingleIndustryQuery(String(id));
  const [deleteIndustry, { isSuccess }] = useDeleteIndustryMutation();
  const navigate = useNavigate();

  const deleteHandler = () => {
    deleteIndustry(String(id))
  }

  useEffect(()=>{
    if(isSuccess)
      navigate(ROUTES.industries)
  }, [isSuccess])

  return (
    <>
      <Loader isLoading={isLoading}/>
      {single && !isLoading &&
      <div className='single-industry'>
        <div className='row'>
          <h4>Industry name</h4>
          <p>{single.name}</p>
        </div>
        {single.projects && single.projects.length > 0 &&
          <div className='single-industry-projects'>
            <h4>Projects</h4>
            {single.projects.map(project => (
              <Link to={`/project/${project.id}`} key={project.id}>
                <p>{project.projectName}</p>
              </Link>
            ))}
          </div>
        }
        <button className='btn-secondary' onClick={deleteHandler}>Delete</button>
      </div>
      }
    </>
  )
}