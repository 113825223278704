import 'tippy.js/dist/tippy.css';
import tippy from 'tippy.js';
import axios from "axios";
import { config } from "../../../../utils/config";
import { headers } from "../../../../utils/helpers";
import { EventDropArg } from "@fullcalendar/react";
import { EventApi } from "@fullcalendar/core";
import { EventResizeDoneArg } from "@fullcalendar/interaction";
import { ResourceAddArg, ResourceApi } from "@fullcalendar/resource-common";
import { IEventModal } from "../../../../types/event";

type MountProps = {
  event: EventApi,
  el:HTMLElement,
  editHandler: ()=>void,
  setEditValues: (d: IEventModal) => void
}
export const eventMount = ({ event, el, editHandler, setEditValues }:MountProps) => {


  el.setAttribute("data-id", event.id);
  const removeBtn = document.createElement('span');
  removeBtn.classList.add('remove-btn')
  removeBtn.textContent = 'X'
  if(el.querySelectorAll('.remove-btn').length === 0) {
    el.appendChild(removeBtn)
  }
  showTooltip(el, event);

  if(event.title !== 'Day Off' && event.title !== 'Sick Leave') {
    const editBtn = document.createElement('span');
    editBtn.classList.add('edit-btn');
    if(el.querySelectorAll('.edit-btn').length === 0) {
      el.appendChild(editBtn);
    }
  }

  (el.querySelector('.remove-btn') as HTMLDivElement).addEventListener('click', ()=>{
    axios.delete(`${config.apiUrl}/events/${event.id}`, headers()).then(()=>{
      event.remove();
    })
  });

  if(el.querySelector('.edit-btn')) {
    (el.querySelector('.edit-btn') as HTMLDivElement).addEventListener('click', () => {
      editHandler && editHandler();
      setEditValues && setEditValues({
        dateStart: event.startStr,
        dateEnd: event.endStr,
        hours: event.extendedProps.hours,
        creator: event.extendedProps.creator,
        project: event.title,
        id: event.id,
      });
    });
  }

  if (event.extendedProps.hours) {
    const name = " - " + event.extendedProps.hours.toString() + "h";
    if(el.querySelectorAll('.fc-event-title-container span') !== null) {
      const hours = document.createElement('span')
      hours.textContent = name;
      if(el.querySelectorAll('.fc-event-title-container .fc-event-title span').length === 0)
        (el.querySelector('.fc-event-title-container .fc-event-title') as HTMLDivElement).appendChild(hours);
    }
  } else if(event.title === 'Sick Leave') {
    el.style.backgroundColor = '#e88e8e';
    el.style.borderColor = '#e88e8e'
  }
  else{
    el.style.backgroundColor = '#f14e4e';
    el.style.borderColor = '#f14e4e'
  }
}

export const eventResize = async (info: EventResizeDoneArg) => {
  const startDate = info.event.startStr;
  const endDate = info.event.endStr;
  const event = info.event.id;
  await axios.patch(`${config.apiUrl}/events/change-date`, {
    'dateStart': startDate,
    'dateEnd': endDate,
    'event': event
  }, headers())
  showTooltip(info.el, info.event);
}

export const eventDrop = async (info: EventDropArg) => {
  let employee;
  const startDate = info.event.startStr;
  const endDate = info.event.endStr;
  const event = info.event.id;
  if (info.newResource != null) {
    employee = info.newResource.id
  } else {
    employee = info.event.getResources().map((resource:ResourceApi) => {
      return resource.id
    })[0]
  }
  await axios.patch(`${config.apiUrl}/events/drag`,
    {
      'dateStart': startDate,
      'dateEnd': endDate,
      'event': event,
      'employee': employee
    },
    headers()
  )
}

export const scrollToToday = () => {
  setTimeout(()=>{
    const scroller = document.querySelector('.fc-scrollgrid td:nth-child(3) .fc-scroller');
    const today = document.querySelectorAll('.fc-day-today')[0] as HTMLElement | null;
    if(today) {
      const offset = today.offsetLeft;
      if (scroller) {
        scroller.scrollTo({
          top: 0,
          left: offset - 50,
          behavior: 'smooth'
        });
      }
    }
  },2000)
}

export const resourceContent = [
  {
    field: 'title',
    cellContent: (arg:ResourceAddArg) => {
      const image = arg.resource.extendedProps.image;
      const title = arg.resource.title;
      if (image)
        return { html: `<p>${title}<img src="${image}" class="deck-image" alt=""></p>` };
      else {
        return { html: `<p>${title}<span class="no-image">N/A</span></p>` };
      }
    }
  },
]

const showTooltip = (el: HTMLElement, event: EventApi) => {
  setTimeout(()=>{
    if((el.querySelector('.fc-event-main-frame') as HTMLDivElement).offsetWidth > 120){
      (el.querySelector('.fc-event-main-frame') as HTMLDivElement).classList.add('max-width');
      (el.querySelector('.fc-event-title') as HTMLDivElement).style.display = 'inline-block';
    }
    else{
      (el.querySelector('.fc-event-title') as HTMLDivElement).style.display = 'none';
      let tooltipName;
      if(event.title !== 'Day Off' && event.title !== 'Sick Leave'){
        tooltipName = event.title + " - " + event.extendedProps.hours.toString() + "h"
      }
      else if(event.title === 'Day Off'){
        tooltipName = 'Day Off'
      }
      else{
        tooltipName = 'Sick Leave'
      }
      tippy(el, {
        content: tooltipName,
        placement: 'top',
        appendTo: document.body,
      });
    }
  }, 200)
}