import React, { useEffect, useState } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { IEmployee, IEmployeeSlack } from "../../../../types/employee";
import { config } from "../../../../utils/config";
import { useRolesQuery } from "../../../../store/role/roleApi";
import {
  useAddEmployeeMutation,
  useEmployeesSlackQuery
} from "../../../../store/employee/employeeApi";
import Modal from "../../../components/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";
import { ISelect } from "../../../../types/project";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { toast } from "react-toastify";

export const NewEmployee = () => {

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<Omit<IEmployee, 'id'>>();
  const { employeesSlack, hash } = useAppSelector(state => state.employee);
  const { roles } = useAppSelector(state => state.role);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployeeSlack>({});
  const [showPopup, setShowPopup] = useState(false);
  const [isCopied, setCopied] = useState(false);
  const [rolesValues, setRolesValues] = useState<ISelect[]>([]);
  const [employeesValues, setEmployeesValues] = useState<ISelect[]>([]);
  const { isLoading } = useEmployeesSlackQuery();
  const [addEmployee, { isError, error, isSuccess }] = useAddEmployeeMutation();
  useRolesQuery();

  const onSubmit = (data: Omit<IEmployee, 'id'>) => {
    addEmployee({
      ...data,
      image: selectedEmployee.image,
      email: selectedEmployee.email
    })
  }

  useEffect(()=>{
    if(isSuccess){
      setShowPopup(true)
    }
  }, [isSuccess])

  useEffect(()=>{
    if(isError){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toast.error(error && 'data' in error && error.data.error.form ? error.data.error.form.email : 'Something went wrong')
    }
  }, [isError])

  useEffect(()=>{
    if(roles){
      const data:ISelect[] = []
      data.push({
        value: '',
        label: 'Select role'
      })
      roles.forEach(role => {
        data.push({
          value: role.id,
          label: role.name,
        })
      })
      setRolesValues(data)
    }
  }, [roles])

  useEffect(()=>{
    if(employeesSlack){
      const data:ISelect[] = []
      employeesSlack.forEach(employee => {
        data.push({
          value: String(employee.email),
          label: String(employee.name),
        })
      })
      setEmployeesValues(data)
    }
  }, [employeesSlack])

  const employeeHandler = (name:string) => {
    if(employeesSlack) {
      setSelectedEmployee(employeesSlack.find(employee => employee.name === name) ?? {})
      setValue('name', name)
    }
  }

  return (
    <>
      <div className='new-employee-wrapper'>
        {!isLoading &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <Select register={register} name={'email'} errors={errors} values={employeesValues} label={'Slack employee'} options={{ required: true }} onChange={employeeHandler}/>
          {selectedEmployee.image &&
          <div className="form-group">
            <img src={selectedEmployee.image} alt="" className='slack-image'/>
          </div>
          }
          <Input type={'text'} label={'Name'} register={register} name={'name'} errors={errors}/>
          <Select register={register} name={'role'} errors={errors} values={rolesValues} label={'Role'} options={{ required: true }}/>
          <button className='btn-primary'>Add and invite</button>
        </form>
        }
      </div>
      <Modal isOpen={showPopup} id={'activate'} setClose={()=>setShowPopup(false)}>
        <div className='popup-link'>
          <h3>Activating Link</h3>
          <div className='form-group'>
            <input type="text" disabled={true} value={`${config.frontUrl}/activation/${hash}`}/>
          </div>
          <CopyToClipboard
            text={`${config.frontUrl}/activation/${hash}`}
            onCopy={() => setCopied(true)}
          >
            <button className='btn btn-primary'>{isCopied ? 'Copied' : 'Copy'}</button>
          </CopyToClipboard>
        </div>
      </Modal>
    </>
  )
}