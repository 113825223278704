import React from 'react'
import { Sidebar } from "../Sidebar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <main className="page-wrapper">
      <Sidebar/>
      <div className='page-wrapper-content'>
        <Outlet/>
      </div>
    </main>
  )
}

export default Layout