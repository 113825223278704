import Input from "../../../components/Input";
import { statuses } from "../constants";
import Checkbox from "../../../components/Checkbox";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IProjectFull, ISelect } from "../../../../types/project";
import { useEmployeesQuery } from "../../../../store/employee/employeeApi";
import { useIndustriesQuery } from "../../../../store/industry/industryApi";
import { useCompaniesQuery } from "../../../../store/company/companyApi";
import { useAppSelector } from "../../../../store/hooks";
import { transformArrayToSelect } from "../../../../utils/helpers";
import { CustomSelect } from "../../../components/CustomSelect";
import { ChromePicker } from 'react-color';

type FormProps = {
  onSubmit: (data: IProjectFull | Omit<IProjectFull, 'id'>) => void,
  isEdit?: boolean,
  initialValues?: IProjectFull
}

export const dirtyValues = (
  dirtyFields: any,
  allValues: IProjectFull | Omit<IProjectFull, 'id'>
): IProjectFull  => {

  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return Object.fromEntries(Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])]));
};

export const ProjectForm = ({ onSubmit, isEdit, initialValues }: FormProps) => {

  const { employees } = useAppSelector(state => state.employee);
  const { projects } = useAppSelector(state => state.project);
  const { industries } = useAppSelector(state => state.industry);
  const { companies } = useAppSelector(state => state.company);
  const { control, register, handleSubmit, formState: { errors, dirtyFields }, setValue, reset } = useForm<IProjectFull>();
  const [employeesValues, setEmployeesValues] = useState<ISelect[]>([]);
  const [industriesValues, setIndustriesValues] = useState<ISelect[]>([]);
  const [companiesValues, setCompaniesValues] = useState<ISelect[]>([]);
  const [activeColor, setActiveColor] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const lastNumber = projects && Math.max(...projects.map(project => project.number))
  const id = isEdit ? true : '';
  useEmployeesQuery();
  useIndustriesQuery();
  useCompaniesQuery();

  useEffect(()=>{
    if(initialValues && isEdit){
      reset(initialValues)
    }
  }, [initialValues])

  useEffect(()=>{
    if(employees){
      setEmployeesValues(transformArrayToSelect(employees, 'id', 'name'))
    }
  }, [employees])

  useEffect(()=>{
    if(industries){
      setIndustriesValues(transformArrayToSelect(industries, 'id', 'name'))
    }
  }, [industries])

  useEffect(()=>{
    if(companies){
      setCompaniesValues(transformArrayToSelect(companies, 'id', 'name'))
    }
  }, [companies])

  useEffect(()=>{
    if(lastNumber && !isEdit)
      setValue('number', lastNumber + 1, { shouldDirty: true })
  },[projects])

  return (
    <form onSubmit={handleSubmit((data)=>onSubmit(dirtyValues({ ...dirtyFields, id }, data)))}>
      <div className='new-project-block'>
        <h3>Main</h3>
        <Input type={'text'} label={'Project name'} register={register} name={'projectName'} errors={errors} options={{ required: true }}/>
        <Input type={'number'} label={'Number'} register={register} name={'number'} errors={errors} options={{ required: true }}/>
        <Controller
          control={control}
          name="status"
          render={({ field: { onChange, value, ref } }) => (
            <CustomSelect
              inputRef={ref}
              value={value ? statuses.find(c => c.value === value) : undefined}
              onChange={(e)=>onChange(e.value)}
              values={statuses}
              label={'Status'}
            />
          )}
        />
        <Controller
          control={control}
          name="industries"
          render={({ field: { onChange, value, ref } }) => (
            <CustomSelect
              label={'Industries'}
              inputRef={ref}
              value={value ? industriesValues.filter(i => value.find(v => i.value == (v.id ? v.id : v))) : null}
              onChange={(e)=>onChange(e.map((c:ISelect) => c.value))}
              values={industriesValues}
              isMultiple
            />
          )
          }
        />
        <Input type={'number'} label={'Estimated hours'} register={register} name={'estimatedHours'} errors={errors}/>
        <Checkbox register={register} name={'isInternal'} errors={errors} label={'Is internal?'}/>
        <Controller
          control={control}
          name="color"
          render={({ field: { onChange, value, ref } }) => (
            <>
              <p className='label'>Color</p>
              <div className='color-point' style={{ backgroundColor: activeColor }} onClick={()=>setShowPicker(!showPicker)}/>
              <ChromePicker color={value} onChange={(color)=>{
                onChange(color.hex)
                setActiveColor(color.hex)
              }} ref={ref} className={showPicker ? 'active' : 'hide'}/>
            </>
          )}
        />
      </div>
      <div className='new-project-block'>
        <h3>Company information and details</h3>
        <Controller
          control={control}
          name="company"
          render={({ field: { onChange, value, ref } }) => (
            <CustomSelect
              inputRef={ref}
              value={value ? companiesValues.find(c => c.value === value.id) : undefined}
              onChange={(e)=>onChange(e.value)}
              values={companiesValues}
              label={'Company'}
            />
          )
          }
        />
        <Input type={'text'} label={'Contact name'} register={register} name={'contactName'} errors={errors}/>
        <Input type={'email'} label={'Email'} register={register} name={'email'} errors={errors}/>
        <Input type={'text'} label={'Phone'} register={register} name={'phone'} errors={errors}/>
        <Checkbox register={register} name={'isHappy'} errors={errors} label={'Is happy?'}/>
      </div>
      <div className='new-project-block'>
        <h3>Participants</h3>
        <Controller
          control={control}
          name="employees"
          render={({ field: { onChange, value, ref } }) => (
            <CustomSelect
              label={'Employees'}
              inputRef={ref}
              value={value ? employeesValues.filter(e => value.find(v => e.value === (v.id ? v.id : v))) : null}
              onChange={(e)=>onChange(e.map((c:ISelect) => c.value))}
              values={employeesValues}
              isMultiple
            />
          )
          }
        />
      </div>
      <button className='btn btn-primary' type={'submit'}>{isEdit ? 'Save' : 'Create'}</button>
    </form>
  )
}