import React from 'react';
import './style.scss'
import classNames from "classnames";

type LoaderProps = {
  isLoading: boolean,
  isDeck?: boolean
}

export const Loader = ({ isLoading, isDeck }:LoaderProps) => {

  const classes = classNames('loader-wrapper', isLoading && 'active', isDeck && 'deck')

  return (
    <div className={classes}>
      <div className="ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}