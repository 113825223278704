import React from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { Link, useParams } from "react-router-dom";
import { useTasksByProjectQuery } from "../../../../store/task/taskApi";

export const Tasks = () => {

  const { tasks } = useAppSelector(state => state.task);
  const { id } = useParams();
  const { isLoading } = useTasksByProjectQuery(String(id))

  return (
    <>
      {tasks && tasks.length > 0 && !isLoading &&
        <>
          <h4>{tasks[0].project.projectName}</h4>
          {tasks.map(task => (
            <div key={task.id} className='task-item'>
              <p>Task name: {task.name}</p>
              <p>Hours: {task.hours}</p>
            </div>
          ))}
        </>
      }
      <Link to={`/task/new/${id}`} className='add-new'>+</Link>
    </>
  )
}