import React, { useEffect } from 'react'
import './style.scss'
import { useAppSelector } from "../../../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import { useDeleteProjectMutation, useSingleProjectQuery } from "../../../../store/project/projectApi";
import { ROUTES } from "../../../../routes/routes";

export const Project = () => {

  const { single } = useAppSelector(state => state.project);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading } = useSingleProjectQuery(String(id));
  const [deleteProject, { isSuccess }] = useDeleteProjectMutation();

  const deleteHandler = () => {
    deleteProject(String(id))
  }

  useEffect(()=>{
    if(isSuccess)
      navigate(ROUTES.projects)
  }, [isSuccess])

  return (
    <>
      <Loader isLoading={isLoading}/>
      {single && !isLoading &&
      <div className='single-project'>
        <div className='project-block'>
          <h3>Main</h3>
          <div className='row'>
            <p>Project name</p>
            <p>{single.projectName}</p>
          </div>
          <div className='row'>
            <p>Number</p>
            <p>{single.number}</p>
          </div>
          {single.status &&
            <div className='row'>
              <p>Status</p>
              <p>{single.status}</p>
            </div>
          }
          {single.isInternal &&
            <div className='row'>
              <p>Project is internal</p>
            </div>
          }
          {single.industries && single.industries.length > 0 &&
            <div className='row'>
              <p>Industries</p>
              <div>
                {single.industries.map(industry => (
                  <p key={industry.id}>{industry.name}</p>
                ))}
              </div>
            </div>
          }
        </div>
        <div className='project-block'>
          <h3>Company information and details</h3>
          {single.company &&
            <div className='row'>
              <p>Company</p>
              <p>{single.company.name}</p>
            </div>
          }
          {single.contactName &&
            <div className='row'>
              <p>Contact name</p>
              <p>{single.contactName}</p>
            </div>
          }
          {single.email &&
            <div className='row'>
              <p>Email</p>
              <p>{single.email}</p>
            </div>
          }
          {single.phone &&
            <div className='row'>
              <p>Phone</p>
              <p>{single.phone}</p>
            </div>
          }
          {single.isHappy &&
            <div className='row'>
              <p>Client is happy</p>
            </div>
          }
        </div>
        <div className='project-block'>
          <h3>Participants</h3>
          {single.employees &&
            <div className='row'>
              <p>Employees</p>
              <div>
                {single.employees.map(employee => (
                  <p key={employee.id}>{employee.name}</p>
                ))}
              </div>
            </div>
          }
        </div>
        <button className='btn-secondary' onClick={deleteHandler}>Delete</button>
      </div>
      }
    </>
  )
}