import React, { useEffect } from 'react'
import './style.scss'
import { IProjectFull } from "../../../../types/project";
import { useAddProjectMutation } from "../../../../store/project/projectApi";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../routes/routes";
import { ProjectForm } from "../components/ProjectForm";

export const NewProject = () => {

  const [addProject, { isSuccess }] = useAddProjectMutation();
  const navigate = useNavigate();

  const onSubmit = (data: Omit<IProjectFull, 'id'>) => {
    addProject(data)
  }

  useEffect(()=>{
    if(isSuccess){
      navigate(ROUTES.projects)
    }
  }, [isSuccess])

  return (
    <div className='new-project-wrapper'>
      <h3>Add project</h3>
      <ProjectForm onSubmit={onSubmit} isEdit={false}/>
    </div>
  )
}