import { createApi } from '@reduxjs/toolkit/query/react'
import { LoginFormData, MeResponse, UserResponse } from "../../types/auth";
import customFetchBase from "../customFetchBase";

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  refetchOnMountOrArgChange: true,
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginFormData>({
      query: (credentials) => ({
        url: 'login_check',
        method: 'POST',
        body: credentials,
      }),
    }),
    me: builder.query<MeResponse, void>({
      query: () => ({
        url: 'me',
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useMeQuery,
  useLazyMeQuery
} = authApi
