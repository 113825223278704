import { createSlice } from '@reduxjs/toolkit';
import { employeeApi } from "./employeeApi";
import { EmployeeState } from "../../types/employee";

const initialState: EmployeeState = {
  employees: null,
  employeesEvents: null,
  employeesSlack: null,
  isActivated: false,
  single: null,
  hash: null,
};

export const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      employeeApi.endpoints.employeesForEvents.matchFulfilled,
      (state, { payload }) => {
        state.employeesEvents = payload.data
      }
    )
    builder.addMatcher(
      employeeApi.endpoints.employeesSlack.matchFulfilled,
      (state, { payload }) => {
        state.employeesSlack = payload.data
      }
    )
    builder.addMatcher(
      employeeApi.endpoints.employees.matchFulfilled,
      (state, { payload }) => {
        state.employees = payload.data
      }
    )
    builder.addMatcher(
      employeeApi.endpoints.singleEmployee.matchFulfilled,
      (state, { payload }) => {
        state.single = payload.data
      }
    )
    builder.addMatcher(
      employeeApi.endpoints.addEmployee.matchFulfilled,
      (state, { payload }) => {
        state.hash = payload.hash
      }
    )
    builder.addMatcher(
      employeeApi.endpoints.activateEmployee.matchFulfilled,
      (state) => {
        state.isActivated = true
      }
    )
    builder.addMatcher(
      employeeApi.endpoints.employeeByHash.matchFulfilled,
      (state, { payload }) => {
        state.single = payload.data
      }
    )
    builder.addMatcher(
      employeeApi.endpoints.inviteEmployee.matchFulfilled,
      (state, { payload }) => {
        state.hash = payload.data.hash
      }
    )
  },
});

export default slice.reducer;
