import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from "../customFetchBase";
import { ITask, ITaskCreate } from "../../types/task";

export const taskApi = createApi({
  reducerPath: 'taskApi',
  baseQuery: customFetchBase,
  tagTypes: ['Task'],
  endpoints: (builder) => ({
    tasksByProject: builder.query<{data: ITask[]}, string>({
      query: (id) => ({
        url: `tasks/${id}`,
        method: 'GET'
      }),
      providesTags: ['Task']
    }),
    addTask: builder.mutation<void, ITaskCreate>({
      query: (data) => ({
        url: `tasks`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Task']
    }),
    changeTask: builder.mutation<void, ITask>({
      query: (data) => ({
        url: `tasks/${data.id}`,
        method: 'PATCH',
        body: data
      }),
    }),
  }),
})

export const {
  useAddTaskMutation,
  useChangeTaskMutation,
  useTasksByProjectQuery,
  useLazyTasksByProjectQuery
} = taskApi
