import { ISelect } from "../types/project";

export const headers = () => {
  return {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem('userToken')
    }
  }
}

export const zeroPad = (num: number | undefined) => String(num).padStart(2, '0')

export const msToTime = (duration: number) => {
  let seconds: string | number = Math.floor((duration / 1000) % 60);
  let minutes: string | number = Math.floor((duration / (1000 * 60)) % 60);
  let hours: string | number = Math.floor(duration / 1000 / 60 / 60);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
}

// eslint-disable-next-line
export const transformArrayToSelect = (arr: any[], value: string, text: string) => {
  const data:ISelect[] = []
  arr.forEach(element => {
    data.push({
      value: element[value],
      label: element[text],
    })
  })
  return data;
}